const images = {
  sidebar: {
    logo: require('./images/Menu_Logo.svg').default,
    house: require('./images/Menu_House.svg').default,
    statistic: require('./images/Menu_Statistic.svg').default,
    account: require('./images/Menu_Account.svg').default,
    vip: require('./images/Menu_VIP.svg').default,
    signal: require('./images/Menu_Signal.svg').default,
    signal_active: require('./images/Menu_Signal_Active.svg').default,
    copy_trade: require('./images/Menu_Copy_Trade.svg').default,
    copy_trade_gold: require('./images/Menu_Copy_Trade_Gold.svg').default,
    bot_trade: require('./images/Menu_Bot_Trade.svg').default,
    bot_trade_gold: require('./images/Menu_Bot_Trade_Gold.svg').default,
    arrow_right: require('./images/Menu_Arrow_Right.svg').default,
    drop_down_gold: require('./images/Menu_Dropdown_Gold.svg').default,
  },
  navbar: {
    sun: require('./images/Navbar_Sun.svg').default,
    moon_gold: require('./images/Navbar_Moon_Gold.svg').default,
    noti: require('./images/Navbar_Noti.svg').default,
    setting: require('./images/Navbar_Setting.svg').default,
    hamburger: require('./images/Navbar_Hamburger.svg').default,
  },
  home: {
    avatar: require('./images/Home_Avatar.png'),
    dropdown: require('./images/Home_Dropdown.svg').default,
    banner_desktop: require('./images/Home_Banner_Desktop.png'),
    banner_tablet: require('./images/Home_Banner_Tablet.png'),
    banner_mobile: require('./images/Home_Banner_Mobile.png'),
    robot: require('./images/Home_Robot.png'),
    robot_blue: require('./images/Home_Robot_Blue.png'),
    star_1: require('./images/Home_Star_1.svg').default,
    star_2: require('./images/Home_Star_2.svg').default,
    star_3: require('./images/Home_Star_3.svg').default,
    star_4: require('./images/Home_Star_4.svg').default,
    star_5: require('./images/Home_Star_5.svg').default,
    star_6: require('./images/Home_Star_6.svg').default,
    star_7: require('./images/Home_Star_7.svg').default,
    star_gold_1: require('./images/Home_Star_Gold_1.svg').default,
    star_gold_2: require('./images/Home_Star_Gold_2.svg').default,
    star_gold_3: require('./images/Home_Star_Gold_3.svg').default,
    star_gold_4: require('./images/Home_Star_Gold_4.svg').default,
    gold_1: require('./images/Home_Gold_1.svg').default,
    gold_2: require('./images/Home_Gold_2.svg').default,
    gold_3: require('./images/Home_Gold_3.svg').default,
    gold_4: require('./images/Home_Gold_4.svg').default,
    welcome_background: require('./images/Home_Welcome_Background.png'),
    gold_wallet: require('./images/Home_Gold_Wallet.svg').default,
    fibo_win: require('./images/Home_Fibo_Win.svg').default,
    white_dropdown: require('./images/Home_White_Arrow.svg').default,
    gold_trade: require('./images/Home_Gold_Trade.svg').default,
    profit: require('./images/Home_Profit.svg').default,
    volume: require('./images/Home_Volume.svg').default,
    win_lose: require('./images/Home_Win_Lose.svg').default,
    aim: require('./images/Home_Aim.svg').default,
    bg_reload: require('./images/Home_Background_Reload.svg').default,
    reload: require('./images/Home_Reload.svg').default,
    save_target: require('./images/Home_Save_Target.svg').default,
    master_name: require('./images/Home_Master_Name_Update.svg').default,
    account_type: require('./images/Home_Account_Type.svg').default,
    bot_account: require('./images/Home_Bot_Account.svg').default,
    volume_amount: require('./images/Home_Volume_Amount.svg').default,
    aim_max: require('./images/Home_Aim_Max.svg').default,
    aim_min: require('./images/Home_Aim_Min.svg').default,
    status: require('./images/Home_Status.svg').default,
    total_volume: require('./images/Home_Total_Volume.svg').default,
    total_profit: require('./images/Home_Total_Profit.svg').default,
    left_arrow_gold: require('./images/Home_Arrow_Left_Gold.svg').default,
    right_arrow_gold: require('./images/Home_Arrow_Right_Gold.svg').default,
  },
  copy: {
    telegram: require('./images/Copy_Telegram.svg').default,
    stock: require('./images/Copy_Stock.svg').default,
    minus: require('./images/Copy_Minus.svg').default,
    plus: require('./images/Copy_Plus.svg').default,
    plus_gold: require('./images/Copy_Plus_Gold.svg').default,
    buy: require('./images/Copy_Buy.svg').default,
    buy_disabled: require('./images/Copy_Buy_Disabled.svg').default,
    sell: require('./images/Copy_Sell.svg').default,
    sell_disabled: require('./images/Copy_Sell_Disabled.svg').default,
    delete: require('./images/Copy_Delete_Gold.svg').default,
    follower: require('./images/Copy_Follower.svg').default,
    settings_enabled: require('./images/Copy_Settings_Enabled.svg').default,
    master_one: require('./images/Copy_Master_One.svg').default,
    checkbox_V: require('./images/Copy_Checkbox_V.svg').default,
    warning: require('./images/Copy_Warning.svg').default,
    account_not_integrate: require('./images/Copy_Account_Not_Integrate.svg')
      .default,
    empty: require('./images/Copy_Empty.png'),
    followingEmpty: require('./images/Copy_Following_Empty.png'),
    blockingEmpty: require('./images/Copy_Blocking_Empty.png'),
    manualEmpty: require('./images/Copy_Manual_Empty.png'),
    masterEmpty: require('./images/Copy_Master_Empty.png'),
  },
  account: {
    table_empty: require('./images/Account_Table_Empty.png'),
    table_delete: require('./images/Account_Table_Delete.svg').default,
    table_login: require('./images/Account_Table_Login.svg').default,
    table_logout: require('./images/Account_Table_Logout.svg').default,
  },
  bot: {
    on: require('./images/Bot_Table_On.svg').default,
    on_gold: require('./images/Bot_Table_On_Gold.svg').default,
    off: require('./images/Bot_Table_Off.svg').default,
    off_gold: require('./images/Bot_Table_Off_Gold.svg').default,
    delete: require('./images/Bot_Table_Delete.svg').default,
    delete_gold: require('./images/Bot_Table_Delete_Gold.svg').default,
    reload: require('./images/Bot_Table_Reload.svg').default,
    reload_gold: require('./images/Bot_Table_Reload_Gold.svg').default,
    completed_V: require('./images/Bot_Completed_V.svg').default,
    information: require('./images/Bot_Table_Information.svg').default,
    add_gold_100: require('./images/Bot_Add_Gold_100.svg').default,
    add_gold_60: require('./images/Bot_Add_Gold_60.svg').default,
  },
  table: {
    left_arrow: require('./images/Table_Left_Arrow.svg').default,
    pagination_left_arrow: require('./images/Table_Pagination_Arrow_Left.svg')
      .default,
    pagination_left_arrow_gold:
      require('./images/Table_Pagination_Left_Arrow_Gold.svg').default,
    right_arrow: require('./images/Table_Right_Arrow.svg').default,
    pagination_right_arrow: require('./images/Table_Pagination_Arrow_Right.svg')
      .default,
    pagination_right_arrow_gold:
      require('./images/Table_Pagination_Arrow_Right_Gold.svg').default,
    signal_right_arrow: require('./images/Table_Signal_Right_Arrow.svg')
      .default,
    actions: require('./images/Table_Actions.svg').default,
    edit: require('./images/Table_Edit.svg').default,
    delete: require('./images/Table_Delete.svg').default,
    log_in: require('./images/Table_Login.svg').default,
    log_out: require('./images/Table_Logout.svg').default,
    buy: require('./images/Table_Buy.svg').default,
    sell: require('./images/Table_Sell.svg').default,
    draw: require('./images/Table_Draw.svg').default,
    search: require('./images/Table_Search.svg').default,
    gift_gold: require('./images/Table_Gift_Gold.svg').default,
  },
  login: {
    eye: require('./images/Login_Eye.svg').default,
    background: require('./images/Login_out_Background.jpg'),
    mobile_background: require('./images/Login_out_Mobile_Background.png'),
    background_tet: require('./images/Login_out_Tet_Background.png'),
    spinning: require('./images/Login_out_Spinning.svg').default,
  },
  toast: {
    info: require('./images/Toast_Info.svg').default,
    check: require('./images/Toast_Check.svg').default,
    error: require('./images/Toast_Error.svg').default,
    warning: require('./images/Toast_Alert.svg').default,
  },
  input: {
    eye: require('./images/Input_Eye.svg').default,
    correct: require('./images/Input_Correct.svg').default,
    dropdown: require('./images/Input_Dropdown.svg').default,
    gold_checkbox: require('./images/Input_Gold_Checkbox.svg').default,
    right_arrow: require('./images/Input_Right_Arrow.svg').default,
    date_picker: require('./images/Input_Date_Picker.svg').default,
    minus: require('./images/Input_Minus.svg').default,
    minus_gold: require('./images/Input_Minus_Gold.svg').default,
  },
  user: {
    block_black: require('./images/User_Block_Black.svg').default,
    block_gold: require('./images/User_Block_Gold.svg').default,
    bot: require('./images/User_Bot.svg').default,
    delete_black: require('./images/User_Delete_Black.svg').default,
    delete_gold: require('./images/User_Delete_Gold.svg').default,
    history: require('./images/User_History.svg').default,
    lock: require('./images/User_Lock.svg').default,
    remove_black: require('./images/User_Remove_Black.svg').default,
    remove_gold: require('./images/User_Remove_Gold.svg').default,
    setting_black: require('./images/User_Setting_Black.svg').default,
    setting_gold: require('./images/User_Setting_Gold.svg').default,
    empty: require('./images/User_Empty.png'),
  },
};

export default images;
