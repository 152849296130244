import { RouteObject } from 'react-router-dom';
import { HomeRoute } from './HomeRoute';
import { ErrorRoute } from './ErrorPageRoute';
import { AboutRoute } from './AboutRoute';
import { CopyTradeRoute } from './CopyTradeRoute';
import { LoginRoute } from './LoginRoute';
import { RegisterRoute } from './RegisterRoute';
import { AccountTradeRoute } from './AccountTradeRoute';
import { TestRoute } from './TestRoute';
import { BotTradeRoute } from './BotTradeRoute';
import { SignalRoute } from './SignalRoute';

const routes: RouteObject[] = [
  HomeRoute,
  AboutRoute,
  AccountTradeRoute,
  SignalRoute,
  CopyTradeRoute,
  BotTradeRoute,
  ErrorRoute,
  LoginRoute,
  RegisterRoute,
  TestRoute,
];

export default routes;
